function Finance() {
  return (
    <ol id="l1">
      <p>Following is our Portfolio of Services related to Finance:</p>
      <ol id="l2">
        <li data-list-text="a.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Home Loan
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Business Loans
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Personal Loans
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            LAP (Loan Against Property)
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            MSME Loans
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Car Loans
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Mudra Loans
          </p>
        </li>
      </ol>
    </ol>
  );
}

export default Finance;
