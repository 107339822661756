import React, { useState } from "react";
import Logo from "../images/Logo1.png";
import useModal from "./modals/Modal";
import { Button } from "react-bootstrap";
import Taxation from "./pages/Taxation";
import Finance from "./pages/Finance";
import Insurance from "./pages/Insurance";
import Investment from "./pages/Investment";
import Legal from "./pages/Legal";
import Others from "./pages/Others";

function Home() {
  const { setShow, CustomModal } = useModal();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [busy, setBusy] = useState("");

  const onLinkClicked = (sTitle) => {
    setTitle(<h3>{sTitle}</h3>);
    if (sTitle === "Taxation") {
      setContent(<Taxation />);
    } else if (sTitle === "Finance") {
      setContent(<Finance />);
    } else if (sTitle === "Insurance") {
      setContent(<Insurance />);
    } else if (sTitle === "Investment") {
      setContent(<Investment />);
    } else if (sTitle === "Legal") {
      setContent(<Legal />);
    } else if (sTitle === "Others") {
      setContent(<Others />);
    }
    setShow(true);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    if (
      document.getElementById("name").value === "" ||
      document.getElementById("email").value === "" ||
      document.getElementById("message").value === ""
    ) {
      setTitle(<h3>Error!</h3>);
      setContent("Please fill all mandatory details(Name, Email and Message).");
      setShow(true);
      return;
    }
    setBusy(true);

    //Refer https://dashboard.emailjs.com/admin/account for email changes
    window.emailjs
      .send("service_a13qxbt", "template_oaywlas", {
        message: document.getElementById("message").value,
        from_email: document.getElementById("email").value,
        from_name: document.getElementById("name").value,
        to_name: "One Consultancy Team",
        reply_to: "hello@1consultancy.in",
      })
      .then((res) => {
        setBusy(false);
        setTitle(<h3>Success!</h3>);
        setContent("Email successfully sent!");
        setShow(true);
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
        document.getElementById("message").value = "";
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
        setTitle(<h3>Error!</h3>);
        setContent("Error while sending email");
        setShow(true);
        setBusy(false);
      });
  };
  return (
    <>
      <div id="wrapper">
        <section id="banner" className="major">
          <div className="inner">
            <header className="major">
              <img src={Logo} alt="" />
            </header>
          </div>
        </section>

        <div id="main">
          <section id="one" className="tiles">
            <article>
              <span className="image">
                <img src="images/pic01.jpg" alt="" />
              </span>
              <header className="major">
                <h3>Taxation</h3>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => onLinkClicked("Taxation")}
                >
                  Click for more information
                </p>
              </header>
            </article>
            <article>
              <span className="image">
                <img src="images/pic02.jpg" alt="" />
              </span>
              <header className="major">
                <h3>Finance</h3>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => onLinkClicked("Finance")}
                >
                  Click for more information
                </p>
              </header>
            </article>
            <article>
              <span className="image">
                <img src="images/pic03.jpg" alt="" />
              </span>
              <header className="major">
                <h3>Insurance</h3>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => onLinkClicked("Insurance")}
                >
                  Click for more information
                </p>
              </header>
            </article>
            <article>
              <span className="image">
                <img src="images/pic04.jpg" alt="" />
              </span>
              <header className="major">
                <h3>Investment</h3>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => onLinkClicked("Investment")}
                >
                  Click for more information
                </p>
              </header>
            </article>
            <article>
              <span className="image">
                <img src="images/pic05.jpg" alt="" />
              </span>
              <header className="major">
                <h3>Legal</h3>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => onLinkClicked("Legal")}
                >
                  Click for more information
                </p>
              </header>
            </article>
            <article>
              <span className="image">
                <img src="images/pic06.jpg" alt="" />
              </span>
              <header className="major">
                <h3>Others</h3>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => onLinkClicked("Others")}
                >
                  Click for more information
                </p>
              </header>
            </article>
          </section>

          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>One Consultancy</h2>
              </header>
              <p>
                One Consultancy was formed to integrate various services
                required by Individuals and provide it all under one roof. The
                team of One Consultancy includes Chartered Accountants, Company
                Secretaries, Cost Accountants who all have enough experience and
                are also experts in the fields they serve. Our area of service
                includes Taxation-be it direct or indirect, Finance, Insurance,
                Investments, Legal and Many more. The main object of One
                Consultancy is to provide a seamless user experience by
                providing almost each service, the client may require which
                relates to the M factor.
              </p>
            </div>
          </section>
        </div>
        <section id="contact">
          <div className="inner">
            <section>
              <form method="post" action="#">
                <div className="fields">
                  <div className="field half">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" />
                  </div>
                  <div className="field half">
                    <label htmlFor="email">Email</label>
                    <input type="text" name="email" id="email" />
                  </div>
                  <div className="field">
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" rows="6"></textarea>
                  </div>
                </div>
                <ul className="actions">
                  <li>
                    <input
                      onClick={sendEmail}
                      type="submit"
                      disabled={busy}
                      value={busy ? "Sending..." : "Send Message"}
                      className="primary"
                    />
                  </li>
                  <li>
                    <input disabled={busy} type="reset" value="Clear" />
                  </li>
                </ul>
              </form>
            </section>
            <section className="split">
              <section>
                <div className="contact-method">
                  <span className="icon solid alt fa-envelope"></span>
                  <h3>Email</h3>
                  <span>hello@1consultancy.in</span>
                </div>
              </section>
              <section>
                <div className="contact-method">
                  <span className="icon solid alt fa-phone"></span>
                  <h3>Phone</h3>
                  <span>(+91) 7874139993</span>
                </div>
              </section>
              <section>
                <div className="contact-method">
                  <span className="icon solid alt fa-home"></span>
                  <h3>Address</h3>
                  <span>
                    One Consultancy
                    <br />
                    UG-26, Hira Panna Mall, Near Maheshwari Bhavan, Citylight
                    <br />
                    Surat - 395007
                  </span>
                </div>
              </section>
            </section>
          </div>
        </section>
        <footer id="footer">
          <div className="inner">
            <ul className="icons">
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/1consultancy.in/"
                  class="icon brands alt fa-facebook" rel="noreferrer"
                >
                  <span className="label">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://instagram.com/1consultancy.in?igshid=YmMyMTA2M2Y="
                  class="icon brands alt fa-instagram"
                  rel="noreferrer"
                >
                  <span class="label">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://wa.me/917874139993"
                  class="icon brands alt fa-whatsapp"
                  rel="noreferrer"
                >
                  <span className="label">Whatsapp</span>
                </a>
              </li>
            </ul>
            <ul className="copyright">
              <li>One Consultancy &copy; {new Date().getFullYear()}</li>
              <li>
                Design:{" "}
                <a href="https://harshadporwal.web.app">Harshad Porwal</a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
      <CustomModal
        title={title}
        content={content}
        buttons={
          <>
            <Button
              style={{ height: "2em", lineHeight: "2em", fontWeight: "100" }}
              variant="primary"
              onClick={() => setShow(false)}
            >
              Close
            </Button>
          </>
        }
      />
    </>
  );
}

export default Home;
