function Taxation() {
  return (
    <ol id="l1">
      <p>Following is our Portfolio of Services related to Taxation:</p>
      <ol id="l2">
        <li data-list-text="a.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Direct
          </p>
          <ol id="l3">
            <li data-list-text="i.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "70pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Planning your taxes
              </p>
            </li>
            <li data-list-text="ii.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "70pt",
                  textIndent: "-16pt",
                  textAlign: "left",
                }}
              >
                Filing income tax returns
              </p>
            </li>
            <li data-list-text="iii.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "70pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                Filing of TDS returns
              </p>
            </li>
            <li data-list-text="iv.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "70pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                PAN/TAN Application
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Indirect
          </p>
          <ol id="l4">
            <li data-list-text="i.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "70pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                GST Registration
              </p>
            </li>
            <li data-list-text="ii.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "70pt",
                  textIndent: "-16pt",
                  textAlign: "left",
                }}
              >
                GST Return Filings
              </p>
            </li>
            <li data-list-text="iii.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "70pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                GST Refunds
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </ol>
  );
}

export default Taxation;
