function Investment() {
  return (
    <ol id="l1">
      <p>Following is our Portfolio of Services related to Investments:</p>
      <ol id="l2">
        <li data-list-text="a.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Demat Account Opening
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Demat Account Handling
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Mutual Funds
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Systematic Investment Plan
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Sovereign Gold Bonds
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Government Bonds
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Unlisted Stocks
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Portfolio Management Services
          </p>
        </li>
      </ol>
    </ol>
  );
}

export default Investment;
