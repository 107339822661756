function Legal() {
  return (
    <ol id="l1">
      <p>Following is our Portfolio of Services related to Legal:</p>
      <ol id="l2">
        <li data-list-text="a.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
           Trademarks
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Incorporation of Company
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Incorporation of Partnership Firm
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Incorporation of Limited Liability Partnership Firm
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            ROC Filings
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            XBRL Filing
          </p>
        </li>
        <li data-list-text="b.">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "50pt",
              textIndent: "-18pt",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            SEBI Related Compliances
          </p>
        </li>
      </ol>
    </ol>
  );
}

export default Legal;
