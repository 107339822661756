import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const useModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const CustomModal = ({ title, content, buttons }) => (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        scrollable
        backdrop="static"
        keyboard={false}
        size="mg"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>{buttons}</Modal.Footer>
      </Modal>
    </>
  );

  return {
    show,
    setShow,
    CustomModal,
  };
};

export default useModal;
